/* GuideStep Component */

.guide--steps {
  display: flex;
  justify-content: space-around;
  margin: 10px;
  /* width: 500px; */
}

.guide--step {
  width: 300px;
  margin: 0 20px 0;
}

.guide--step--words {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.step--intro {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 64px;
}

.number--big {
  font-size: 80px;
  font-weight: 800;
  margin: 0 16px 0 0;
}

.step--title {
  font-size: 25px;
  font-weight: 800;
  /* margin-bottom: 20px; */
}

.step--memo {
  margin: 0;
  width: 210px;
}

.guide--step--image {
  height: 185px;
  width: 300px;
  overflow: hidden;
  background-color: white;
  margin: 30px auto 50px;
}

.guide--image--src {
  width: 300px;
  height: auto;
}
