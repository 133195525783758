/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c4c4c4;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider::before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #00ace0;
}

/* input:focus + .slider {
  box-shadow: 0 0 0px #56a8d8;
} */

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 17px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Toggle container styling */

.toggle--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 19px;
  width: 155px;
}

.toggle--label {
  width: 85px;
  height: 19px;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;
  color: #828282;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 9px 0px;
}
