.footer {
  /* position: absolute; */
  /* bottom: 0px; */
  position: fixed;
  bottom: 0px;
  /* background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  backdrop-filter: blur(3px); */
}

.footer p {
  font-size: 14px;
  text-align: center;
  color: #828282;
  margin: 0;
  padding: 10px;
  width: 100vw;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  backdrop-filter: blur(3px);
}

.app-link {
  font-weight: 500;
  color: #4f4f4f;
  /* text-decoration: none; */
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  backdrop-filter: blur(3px);
}

.heart {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  backdrop-filter: blur(3px);
}
