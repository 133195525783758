* {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*:focus {
  outline: none;
  /* outline-style: solid; */
  /* outline-color: gray; */
  /* outline-width: 1px; */
}

h1 {
  font-size: 55px;
  font-weight: 700;
  color: black;
}

h2 {
  /* font-style: normal; */
  font-size: 40px;
  font-weight: bold;
  color: black;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 20px;
  font-weight: 400;
  color: #828282;
}

p {
  font-size: 14px;
  font-weight: 300;
  color: #828282;
  margin: 8px;
  /* opacity: 0.67; */
}

label {
  margin: 20px;
  font-size: 20px;
  font-weight: 400;
  color: #828282;
  /* opacity: 0.67; */
}

.division--line {
  border: 0;
  border-top: 1.5px solid #e0e0e0;
  width: 1020px;
  /* padding: 0; */
  margin: 40px auto 80px;
  opacity: 0.7;
}

.inline--hyperlink {
  /* font-weight: bold; */
  /* font-size: 20px; */
  /* margin: auto; */
  color: black, 100%;
  cursor: pointer;
  justify-self: flex-end;
  text-decoration: none;
}

.inline--hyperlink:visited {
  color: black;
}

a {
  color: gray;
}

a:visited {
  color: gray;
}

/* .inline--hyperlink a:visited {
  color: black;
} */

/* Page-level settings */

.app {
  display: flex;
  align-items: center;
  height: auto;
  justify-content: flex-start;
  flex-direction: column;
  /* background: #fffdf8; */
}

.home,
.convert,
.collection {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  flex-basis: content;
}

.redirect--to--about {
  font-size: 18px;
}

.forClipboard {
  position: fixed;
  opacity: 0;
}

/* 

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(80, 80, 80);
}

.App-link {
  color: #fbdf61;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
