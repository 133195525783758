.cards {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto;
  column-gap: 40px;
  row-gap: 25px;
  margin: 0 auto 60px;
  min-height: 28vh;
  padding: 0;
  /* transition: 0.3s; */
}

.quickView {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 120px;
}

.detailedView {
  /* display: none; */
  /* display: flex; */
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 310px;
  padding: 4px 0;
}

/* .collection.detailedView {
  display: none;
} */

.card--item {
  margin: 0;
  padding: 18px;
  background: #ffffff;
  width: 242px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  height: max-content;
}

.field {
  padding: 4px 0;
  margin: 2px;
  word-wrap: break-word;
}

.card--title {
  font-weight: bold;
  font-size: 14px;
  color: #000000;
  overflow: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: auto;
  width: 198px;
  background: #ffffff;
}

.card--title.field {
  margin: 4px 2px 6px;
  /* height: 17px; */
}

.card--title:hover {
  background-color: #efeeec;
  border-radius: 2px;
}

.card--title:focus {
  background-color: #f8f8f8;
  border-radius: 2px;
}

/* detail views */

.card--label {
  font-weight: 600;
  font-size: 8px;
  padding: 0;
  margin: 0 2px;
  /* line-height: 10px; */

  letter-spacing: 0.1em;
  color: #bdbdbd;
  background: #ffffff;
}

.solid--line {
  border: 0;
  border-top: 1px solid #e0e0e0;
  width: 198px;
  padding: 0;
  margin: 2px auto 8px;
  opacity: 0.7;
}

.card--memo {
  font-size: 10px;
  width: 198px;
  background: #ffffff;
  color: #4f4f4f;
  opacity: 0.9;
}

.card--memo:hover {
  background-color: #efeeec;
  border-radius: 2px;
}

.card--memo:focus {
  background-color: #f8f8f8;
}

.quickView .card--memo.field {
  font-size: 12px;
  height: auto;
  max-height: 65px;
}

.card--memo.field {
  /* height: auto; */
  max-height: 44px;
  overflow: auto;
}

.card--text {
  font-size: 10px;
  line-height: 12px;
  background: #ffffff;
  width: 198px;
  color: #828282, 100%;
}

.card--text.anchored--page {
  color: #4f4f4f;
  text-decoration: none;
  margin-bottom: 0px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: auto;
}

.card--text.anchored--page:hover {
  background-color: #efeeec;
  border-radius: 2px;
}

.notion--icon {
  height: 11px;
  width: 11px;
  margin: 1px 4px 0;
}

.card--pair {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* padding: 0px; */
  margin: 6px auto;
}

.card--actions {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 12px;
  text-align: left;
  /* height: 13px; */
  /* padding: 6px; */
  /* position: absolute;
    bottom: 0; */
}

.copy--btn {
  font-weight: bold;
  font-size: 11px;
  cursor: pointer;
}

.delete--btn {
  font-weight: bold;
  font-size: 11px;
  color: #cf5252;
  cursor: pointer;
}
