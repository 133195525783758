.navbar {
  position: fixed;
  margin: auto;
  left: 0;
  width: 100vw;
  padding: 1.5rem 0;
  z-index: 99;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  backdrop-filter: blur(3px);
}

.content--container {
  max-width: 1200px;
  display: flex;
  margin: auto;
  justify-content: space-between;
  align-items: center;
}

.navbar-logo {
  font-size: 20px;
  font-weight: bold;
  color: black !important;
  cursor: pointer;

  justify-self: flex-start;
  text-decoration: none;
  align-items: center;
  margin-left: 20px;
  display: flex;
  align-items: center;
  /* position: absolute; */
}

.nav-menu {
  display: flex;
  justify-content: flex-end;
  width: 150px;
  /* margin-right: 20px; */
  /* display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 20px; */
  list-style: none;
  text-align: center;
}

.nav-item {
  font-weight: 500;
  font-size: 16px;
  margin-right: 20px;
  padding: 6px 10px;
  /* color: #bdbdbd; */
  color: gray;
  cursor: pointer;
  justify-self: flex-end;
  text-decoration: none;
  border-radius: 3px;
}

.nav-item.active {
  color: black;
}

.nav-item:hover {
  background-color: #efeeec;
  /* color: black; */
}
