/* .convert {
  display: flex;
  flex-direction: column;
  align-items: center;
} */

.convert--headline {
  margin-top: 155px;
  margin-bottom: 10px;
}

.convert--section {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
}

.inputSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  margin: 30px auto;
}

input {
  width: 400px;
  height: 50px;
  background: #ffffff;
  border: 1px solid rgba(196, 196, 196, 0.4);
  box-sizing: border-box;
  border-radius: 6px;
  font-size: 18px;
  line-height: 21px;
  /* color: #C4C4C4; */
  padding: 16px;
}

.redirect--to--about {
  margin: 20px auto 40px;
  color: gray;
}

.submit--btn {
  margin: 0 0 0 20px;
}

.collectionIntro {
  margin: 15px 0 20px;
  /* width: 800px; */
  /* background-color: #f3f2ee; */
  border-radius: 5px;
  text-align: center;
  /* color: black; */
}

.collection--headline {
  position: absolute;
  top: 190px;
}

.detail--view--toggle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 35px;
}
