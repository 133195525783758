.landing--section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-basis: content;
}

.intro {
  margin: 150px auto 20px;
  text-align: center;
  width: 667px;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
}

.intro h1 {
  margin: 20px;
  width: 900px;
}

.intro p {
  font-size: 24px;
  margin: 10px;
  width: 800px;
  box-sizing: border-box;
}

.guide {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.guide .guide--steps {
  margin: 40px auto 20px;
}

.start--button {
  margin: 10px;
}

.demoBackground {
  padding: 20px;
  margin: 50px;
  background-color: #f3f0e9;
}

.demo--gif--src {
  width: 60vw;
  max-width: 744px;
  height: auto;
  margin: 50px 100px;
}

.gifs {
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.placeHolder {
  height: 140px;
}
