.tabs {
  margin: 10px;
}

.tabButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 30px;
}

.tabContents {
  margin: auto;
}

.tabs button {
  background-color: transparent;
  /* float: left; */
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 10px;
  font-weight: 500;
  color: gray;
  margin: 4px;
  /* margin: 10px; */
  /* transition: 0.3s; */
  font-size: 15px;
  /* -webkit-transition: 0.3s; */
  border-radius: 3px;
}

.tabs button:hover {
  background-color: #efeeec;
}

.tabs button.active {
  color: black;
  background-color: transparent;
  background-image: linear-gradient(to right, black 0%, black 100%);
  background-repeat: repeat-x;
  background-position: 0px 100%;
  background-size: 100% 2px;
  /* border-bottom: #4f4f4f;
    border-width: 1px; */
}
