:root {
  --primary: #4f4f4f;
}

.btn {
  padding: 6px 25px;
  outline: none;
  border: none;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  line-height: 38px;
  border-radius: 6px;
  /* display: inline-block; */
  background-color: var(--primary);
  filter: drop-shadow(0px 5px 10px rgba(89, 137, 129, 0.2));
  white-space: nowrap;
}

.btn--pimary {
  color: #4f4f4f;
  border: 1px solid var(--primary);
}

/* .btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
  }
  
  .btn--medium {
    padding: 8px 20px;
    font-size: 20px;
  }
  
  .btn--large {
    padding: 12px 26px;
    font-size: 20px;
  }
  
  .btn--medium:hover,
  .btn--large:hover {
    background: #fff;
    color: #242424;
    transition: all 0.3s ease-out;
  } */
